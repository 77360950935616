<template>
  <div class="device-index-container" v-if="deviceInfo && deviceInfo.machine">
    <div v-if="$tools.isAndroid()" class="download f fbt fc">
      <div class="download-left f">
        <div class="download-icon f fvc">
          <img src="https://img-fe.tengzhihh.com/image/414d3088a8da7ee76be929f5343f6baf-52x39.png" alt="" />
        </div>
        <div class="download-title">眷吉App客户端上线<br />操作更加顺畅，更多可用功能</div>
      </div>
      <van-button class="downloac-btn primary-btn" @click="downloadFn"> 下载 </van-button>
    </div>
    <div class="index-out-box">
      <!-- <div class="banner" @click="bannerClick" v-show="bannerShow">
        <img :src="bannerBg" class="bg" />
        <img src="https://img-fe.tengzhihh.com/image/4ae9e7045ecbb5-190x86.png" class="btn" />
      </div> -->

      <GoBack :backFunction="goHome" :homeTop="$tools.isAndroid() ? '118px' : '68px'" type="home" />

      <festivalCalendar
        :style="$tools.isAndroid() ? { top: '123px' } : { top: '73px' }"
        v-if="latelyFestival.length"
        class="festivalCalendar"
        :list="latelyFestival"
      />

      <div class="header-icon f fc fbt">
        <div class="f fc">
          <img :src="batteryIcon" class="btn-img" />
          <img :src="incenseIcon" class="btn-img" />
        </div>
        <div class="playing-box f fc fbt" v-if="isLessonStarted">
          <div class="f fc" @click="goPlaying">
            <img src="https://img-fe.tengzhihh.com/image/fb2f93f37e1ddf-36x36.png" class="icon1" />
            <div class="name clamp1">{{ playingName }}</div>
          </div>
          <div class="f fc">
            <img v-show="isPlaying" src="https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif" class="icon2" />
            <div class="exit-btn" @click="exit">退出</div>
          </div>
        </div>
        <!-- <div class="playing-box f fc fbt" v-if="isMusicStarted">
          <div class="f fc" @click="goPlaying">
            <img src="https://img-fe.tengzhihh.com/image/fb2f93f37e1ddf-36x36.png" class="icon1" />
            <div class="musicPlay">
              <div class="musicName">{{ playingName }}</div>
              <div class="musicProgress">
                <van-progress :percentage="50" stroke-width="3px" :color="'#f87a1b'" :show-pivot="false" />
              </div>
            </div>
          </div>
        </div> -->
        <div class="f fc">
          <!-- clickImgType用于实现点击图片变动，V2版本暂时无此变动 -->
          <img
            src="https://img-fe.tengzhihh.com/image/b59dc011a2374d-60x60.png"
            @click="useInstructions"
            v-show="clickImgType !== 'instructions'"
            class="btn-img"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/b59dc011a2374d-60x60.png"
            v-show="clickImgType === 'instructions'"
            class="btn-img"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/04b68e52664a82-60x60.png"
            v-show="clickImgType !== 'setting'"
            @click="$utils.checkAuth(serialId, goSetting)"
            class="btn-img"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/04b68e52664a82-60x60.png"
            v-show="clickImgType === 'setting'"
            class="btn-img"
          />
        </div>
      </div>

      <img :src="deviceInfo.machine.wap_background_image" class="background-img" />

      <div class="index-box">
        <div class="incense-box">
          <div id="yan-svga-device-index" class="yan-svga"></div>

          <img :src="deviceInfo.incense.image" @click="handleIncense" class="incense-img" />
        </div>
        <img
          :src="'https://img-fe.tengzhihh.com/image/b5e8e8702186fa09dcf4c2afb7dc773c-94x110.png'"
          class="task-img"
          @click="handleTask"
        />

        <div class="fude-box" @click="showFude" v-if="$utils.calcUserIsMasterWithSerialId(serialId)">
          <div class="num">{{ deviceInfo.machine.user_fude || 0 }}</div>
          <img src="https://img-fe.tengzhihh.com/image/9a2fc540f310fa-128x128.png" class="bg" />
        </div>

        <img
          :src="deviceInfo.curriculum.image || 'https://img-fe.tengzhihh.com/image/db43a3a12f2312-92x142.png'"
          class="lesson-img"
          @click="handleLesson"
        />

        <img :src="deviceInfo.music.image" @click="handleMusic" class="music-img" />

        <!-- <img :src="deviceInfo.incense.click_image" v-show="clickImgType === 'incense'" class="incense-img" /> -->

        <img
          :src="deviceInfo.family_motto.image"
          @click="handleMotto"
          v-show="clickImgType !== 'family_motto'"
          class="family-motto-img"
        />
        <img v-if="wooden_fish.image" :src="wooden_fish.image" class="woodfish-img" @click="woodfishFn" />
        <img
          :src="deviceInfo.family_motto.image"
          @click="handleMotto"
          v-show="clickImgType === 'family_motto'"
          class="family-motto-img"
        />
        <!-- V2暂时没有click_image -->
        <!-- <img
          :src="deviceInfo.family_motto.click_image"
          v-show="clickImgType === 'family_motto'"
          class="family-motto-img"
        /> -->

        <!-- 多柜模式 -->
        <div class="multi-guizi-box" v-if="!soloMode">
          <img :src="deviceInfo.machine.main_body_image" class="guizi-img" />

          <img
            src="https://img-fe.tengzhihh.com/image/26b2cc7b06f2e3-207x207.png"
            v-for="(item, index) in fireList"
            :key="index"
            :id="`fire-${fireList.length === 6 ? index : index + 6}`"
            class="fire-ani"
          />

          <div class="god-box">
            <img :src="deviceInfo.god.image" @click="handleGod('multi')" class="god-img click-hover" />
            <!-- <img
              v-if="deviceInfo.god.left_golden_flag_image"
              :src="deviceInfo.god.left_golden_flag_image"
              class="left-flag-image"
            />
            <img
              v-if="deviceInfo.god.right_golden_flag_image"
              :src="deviceInfo.god.right_golden_flag_image"
              class="right-flag-image"
            /> -->
            <img v-if="deviceInfo.god.left_lamp_image" :src="deviceInfo.god.left_lamp_image" class="left-lamp-image" />
            <img
              v-if="deviceInfo.god.right_lamp_image"
              :src="deviceInfo.god.right_lamp_image"
              class="right-lamp-image"
            />
          </div>

          <div class="earth-god-box">
            <img :src="deviceInfo.earth_god.image" @click="handleEarthGod('multi')" class="earth-god-img" />
            <!-- <img :src="deviceInfo.earth_god.left_golden_flag_image" class="left-flag-image" />
            <img :src="deviceInfo.earth_god.right_golden_flag_image" class="right-flag-image" /> -->
            <img :src="deviceInfo.earth_god.left_lamp_image" class="left-lamp-image" />
            <img :src="deviceInfo.earth_god.right_lamp_image" class="right-lamp-image" />
          </div>

          <div class="deceased-box">
            <div class="deceased-avatar click-hover" @click="handleClick('multi')">
              <div>
                <img v-if="deviceInfo.deceased.avatar" :src="deviceInfo.deceased.avatar" class="avatar" />
                <img v-if="deviceInfo.deceased.avatar" :src="deviceInfo.deceased.image" class="bg" />
                <div v-if="!deviceInfo.deceased.avatar" class="paiwei f">
                  <img
                    :src="
                      deviceInfo.deceased.image ||
                      'https://img-fe.tengzhihh.com/image/9bfdaf39ae173d8970ee25236b3322c4-314x662.png'
                    "
                  />
                  <div class="deceased-avatar-text f fvc">
                    <div class="paiwei-title">{{ deviceInfo.deceased.title }}</div>
                  </div>
                </div>
              </div>
            </div>
            <img :src="deviceInfo.deceased.left_lamp_image" class="left-lamp-image" />
            <img :src="deviceInfo.deceased.right_lamp_image" class="right-lamp-image" />
          </div>
        </div>

        <!-- 单柜模式 -->
        <div class="solo-guizi-box" v-else>
          <img :src="deviceInfo.machine.main_body_image" class="guizi-img" />

          <img
            src="https://img-fe.tengzhihh.com/image/26b2cc7b06f2e3-207x207.png"
            v-for="(item, index) in fireList"
            :key="index"
            :id="`fire-${fireList.length === 6 ? index : index + 6}`"
            class="fire-ani"
          />

          <div v-if="indexMode === 'deceased'" class="deceased-mode">
            <div v-if="deviceInfo.deceased.decease_show == 1" class="enshrine-center-0" @click="handleClick('solo')">
              <img
                :src="
                  deviceInfo.deceased.image ||
                  'https://img-fe.tengzhihh.com/image/9bfdaf39ae173d8970ee25236b3322c4-314x662.png'
                "
              />
              <div class="deceased-text f fvc">
                <p class="enshrine-name">{{ deviceInfo.deceased.title }}</p>
              </div>
            </div>
            <div v-else class="deceased-box click-hover" @click="handleClick('solo')">
              <div>
                <img :src="deviceInfo.deceased.image" class="bg" />
                <img :src="deviceInfo.deceased.avatar" class="avatar" />
              </div>
            </div>

            <img :src="currentOfferObj.left_lamp_image" class="left-lamp-image" />
            <img :src="currentOfferObj.right_lamp_image" class="right-lamp-image" />
          </div>

          <div v-else class="solo-god-mode">
            <img :src="currentOfferObj.image" class="god-img" @click="handleClick('solo')" />
            <img :src="currentOfferObj.left_lamp_image" class="left-lamp-image" />
            <img :src="currentOfferObj.right_lamp_image" class="right-lamp-image" />
            <!-- <img
              v-if="currentOfferObj.left_golden_flag_image"
              :src="currentOfferObj.left_golden_flag_image"
              class="left-flag-image"
            />
            <img
              v-if="currentOfferObj.right_golden_flag_image"
              :src="currentOfferObj.right_golden_flag_image"
              class="right-flag-image"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <van-overlay z-index="9999999" :show="showOverlay" @click="showOverlay = false">
      <div class="wrapper">
        <img
          class="wrapper-icon wrapper-icon1 f"
          src="https://img-fe.tengzhihh.com/image/3225aa36834982fa6d2d82f938067906-528x198.png"
          alt=""
          srcset=""
        />
        <img
          class="wrapper-icon wrapper-icon2 f"
          src="https://img-fe.tengzhihh.com/image/f679c282c9a150e469f6793754789d22-356x356.png"
          alt=""
          srcset=""
        />
        <img
          class="wrapper-icon wrapper-icon3 f"
          src="https://img-fe.tengzhihh.com/image/e7b81b975c5b33b8309b0bba1c5fe708-166x171.png"
          alt=""
          srcset=""
        />
      </div>
    </van-overlay>

    <Dialog ref="dialog" />
  </div>
</template>

<script>
import festivalCalendar from './components/festivalCalendar';
import apiPath from '@/utils/apiPath';
import bus from '@/utils/bus';
import dayjs from 'dayjs';
import Dialog from '@/components/Dialog/Dialog.vue';
import { mapState } from 'vuex';
import sendMsg from '@/webSocket/sendMsg';
import GoBack from '@/components/GoBack/GoBack.vue';
import svgaplayerweb from 'svgaplayerweb';
import { getMusic } from '@/api/woodfish';
import { getStore, setStore } from '@/utils/localStore';
import { commonOperate } from '@/mixins/commonOperate';
const BatteryStatusImgArr = [
  'https://img-fe.tengzhihh.com/image/e2faa48d8acf5d-60x60.png',
  'https://img-fe.tengzhihh.com/image/f2cdb374417863-60x60.png',
  'https://img-fe.tengzhihh.com/image/20d536ed73e356-60x60.png',
  'https://img-fe.tengzhihh.com/image/85513c4ab29314-60x60.png',
];
const incenseStatusArr = [
  'https://img-fe.tengzhihh.com/image/28e8b3fe47d883-60x60.png',
  'https://img-fe.tengzhihh.com/image/f455f9785d656e-60x60.png',
  'https://img-fe.tengzhihh.com/image/6a4ef400baaadc-60x60.png',
  'https://img-fe.tengzhihh.com/image/6ae0e181810bb6-60x60.png',
];
export default {
  mixins: [commonOperate],
  components: { Dialog, GoBack, festivalCalendar },
  data() {
    return {
      deviceInfo: null,
      serialId: '',
      clickImgType: '',
      clickTimeout: null,
      svgaOptions: {
        loop: 1,
        fillMode: 'forwards',
        playMode: 'forwards',
        startFrame: 0,
        endFrame: 0,
        autoPlay: false,
      },
      batteryIsCharging: false,
      batteryLevel: 100,
      incenseCapacity: 100,
      bannerBg: '',
      bannerShow: false,
      timeout: null,
      timeout1: null,
      startedLessonId: '',
      startedMusicId: '',
      startedLessonCategoryId: 0,
      isLessonStarted: false,
      isMusicStarted: false,
      isPlaying: false,
      lessonList: [],
      latelyFestival: [],
      wooden_fish: {},
      localBatteryStatus: '',
      showOverlay: false,
    };
  },
  computed: {
    ...mapState(['siMiaoLianMingGodGoodsId']),
    ...mapState(['musicList']),
    playingName: function () {
      if (this.isMusicStarted && this.startedMusicId) {
        return this.musicList.find(i => i.id == this.startedMusicId)?.name;
      } else if (this.isLessonStarted && this.startedLessonId) {
        return this.lessonList.find(i => i.id == this.startedLessonId)?.name.slice(0, 5);
      } else {
        return '';
      }
    },
    indexMode: function () {
      return this.deviceInfo.machine.index_mode;
    },
    soloMode: function () {
      return !!['deceased', 'god', 'earth_god', 'western'].includes(this.indexMode);
    },
    currentOfferObj: function () {
      return ['god', 'western'].includes(this.indexMode)
        ? this.deviceInfo.god
        : this.indexMode === 'earth_god'
        ? this.deviceInfo.earth_god
        : this.deviceInfo.deceased;
    },
    fireList: function () {
      return this.soloMode ? new Array(2) : new Array(6);
    },
    batteryIcon: function () {
      if (this.batteryIsCharging) {
        return BatteryStatusImgArr[0];
      } else {
        if (this.batteryLevel >= 0 && this.batteryLevel < 20) {
          return BatteryStatusImgArr[1];
        } else if (this.batteryLevel >= 20 && this.batteryLevel <= 60) {
          return BatteryStatusImgArr[2];
        } else {
          return BatteryStatusImgArr[3];
        }
      }
    },
    incenseIcon: function () {
      if (this.incenseCapacity >= 70) {
        return incenseStatusArr[0];
      } else if (this.incenseCapacity >= 40 && this.incenseCapacity < 70) {
        return incenseStatusArr[1];
      } else if (this.incenseCapacity >= 5 && this.incenseCapacity < 40) {
        return incenseStatusArr[2];
      }
      return incenseStatusArr[3];
    },
  },
  created() {
    this.serialId = this.$route?.query?.serial_id;
    this.getMusic();
  },
  mounted() {
    this.init();
    this.getLesson();
  },
  beforeDestroy() {
    bus.$off('onMsg');
    clearTimeout(this.timeout);
    clearTimeout(this.timeout1);
    setStore({
      name: `localBatteryStatus-${this.serialId}`,
      content: this.incenseCapacity,
    });
  },

  watch: {
    clickImgType: function (n) {
      if (n) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(() => {
          this.clickImgType = '';
        }, 300);
      }
    },
  },
  methods: {
    downloadFn() {
      this.$http
        .get(apiPath.systemConfig, {
          identifier: 'last_app',
        })
        .then(({ data }) => {
          if (this.$tools.isWeChat()) {
            var url = new URL(window.location.href);
            var params = new URLSearchParams(url.search);
            params.set('downloadUrl', data.content);
            url.search = params.toString();
            history.replaceState(null, '', url.href);
            this.showOverlay = true;
            return;
          } else {
            this.$router.replace({
              path: this.$route.path,
              query: Object.assign(
                {
                  downloadUrl: data.content,
                },
                this.$route.query
              ),
            });
          }
        });
    },
    init() {
      this.getUserDevices();
      this.getDeviceIndex();
      this.initCapacity();
    },
    initCapacity() {
      const incenseCapacity = getStore({
        name: `localBatteryStatus-${this.serialId}`,
      });
      if (!incenseCapacity) return;
      this.incenseCapacity = incenseCapacity;
    },
    async getMusic() {
      if (this.musicList?.length || !this.serialId) return;
      const res = await getMusic({
        serial_id: this.serialId,
        from: 'user',
      });
      this.$store.commit('setMusicList', res.data.list);
    },
    showConfirm(cb) {
      this.$refs.dialog.initDialog({
        title: '提示',
        content: `神台机正在进行功课，是否先退出？`,
        confirmText: '确认',
        cancelText: '取消',
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
          typeof cb === 'function' && cb();
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    confirmExit() {
      this.$track('interrupt_gongke');
      sendMsg('player', {
        operation: 'stop',
        playerType: 'classes',
      });
    },
    exit() {
      this.showConfirm(() => {
        this.confirmExit();
      });
    },
    getLesson() {
      this.$http
        .get(apiPath.lessonList, {
          serial_id: this.serialId,
          size: 100,
        })
        .then(res => {
          this.lessonList = res.data.list;
        });
    },
    getUserDevices() {
      this.$http.get(apiPath.getUserDevices).then(res => {
        this.$store.commit('setUserMachinesList', res.data.list);
      });
    },
    goHome() {
      this.$track('return_shouye_button_click');
      this.$router.push('/');
    },
    goTask() {
      this.$router.push({ name: 'dailyTaskList' });
    },
    getSystemInfo() {
      sendMsg('systemInfo', {
        operation: 'getBatteryStatus',
      });
      sendMsg('player', {
        operation: 'playerStatus',
      });
    },
    showFude() {
      this.$router.push({
        name: 'rankList',
        query: this.$route.query,
      });
    },

    // setGodDefault() {
    //   const formData = new FormData();
    //   formData.append('serial_id', this.serialId);
    //   formData.append('god_type', 1);
    //   this.$http.formPost(apiPath.setGodDefault, formData).then(() => {
    //     this.getDeviceIndex();
    //   });
    // },
    bannerClick() {
      const id = this.deviceInfo?.banner[0]?.id;
      id && this.$router.push(`/shop/goodsDetail?id=${id}&from=index_banner&serial_id=${this.serialId}`);
    },
    goPlaying() {
      if (!this.startedLessonCategoryId || !this.startedLessonId) {
        return false;
      }
      this.$router.push(
        `/music/musicIndex?from=lesson&serial_id=${this.serialId}&lesson_id=${this.startedLessonId}&lesson_category_id=${this.startedLessonCategoryId}`
      );
      this.$tools.setDocumentTitle('佛音播放页');
    },
    handleMsg(msg) {
      console.warn('收到消息：', msg);
      const action = msg.action;
      const operation = msg.data.operation;
      const map = {
        player: {
          cb: () => {},
          operation: {
            playerStatus: () => {
              this.updatePlayerStatus(msg);
            },
            onProgressChanged: () => {
              this.updatePlayerStatus(msg);
            },
          },
        },
        systemInfo: {
          operation: {
            updateBatteryStatus: () => {
              this.updateBatteryStatus(msg);
            },
            godListRefresh: () => {
              this.init();
            },
            indexModeRefresh: () => {
              this.init();
            },
            deceaseListRefresh: () => {
              this.init();
            },
            earthGodListRefresh: () => {
              this.init();
            },
          },
        },
      };

      const actionCb = map[action]?.cb;
      const operationCb = map[action] && map[action]['operation'] && map[action]['operation'][operation];
      typeof actionCb === 'function' && actionCb();
      typeof operationCb === 'function' && operationCb();
    },
    updatePlayerStatus(msg) {
      this.isLessonStarted = msg.data.isStarted && msg.data.playerType === 'classes';
      this.isMusicStarted = msg.data.isStarted && msg.data.playerType === 'music';
      this.isPlaying = msg.data.isPlaying;
      if (msg.data.isStarted) {
        this.startedLessonId = msg.data.classesId;
        this.startedMusicId = msg.data.musicId;
        this.startedLessonCategoryId = this.lessonList.find(i => i.id == this.startedLessonId)?.category_id;
      } else {
        this.startedLessonId = '';
        this.startedMusicId = '';
        this.startedLessonCategoryId = '';
      }
    },
    updateBatteryStatus(msg) {
      console.log(msg);
      // "batteryLevel": 99,//电池电量
      // "batteryIsCharging":true,//电池是否正在充电
      // "incenseCapacity":40,//香薰容量
      this.batteryIsCharging = msg.data.batteryIsCharging;
      this.batteryLevel = msg.data.batteryLevel;
      this.incenseCapacity = msg.data.incenseCapacity;
    },
    changeImgType(type, cb) {
      this.clickImgType = type;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        typeof cb === 'function' && cb();
      }, 200);
    },
    getDeviceIndex() {
      this.$http
        .get(apiPath.getDeviceIndex, {
          serial_id: this.serialId,
          ver: '1.1',
        })
        .then(res => {
          if (res.data?.god?.id === 0 && !res.data?.machine?.index_mode && !res.data?.machine?.religion) {
            this.$toast('该神台机还未初始化，请先设置');
            return this.$router.replace(`/settings/initSelect?serial_id=${this.serialId}&from=mode_select`);
          }
          this.latelyFestival = res.data.festival;
          if (res.data.deceased.title.length > 8) {
            res.data.deceased.title = res.data.deceased.title.substr(0, 8);
          }
          this.deviceInfo = res.data;
          this.bannerBg = res.data?.banner[0]?.wap_banner_image;
          this.wooden_fish = res.data.wooden_fish;
          const bannerDuration = res.data?.banner[0]?.show_duration;
          const lastBannerShowDate = localStorage.getItem('lastBannerShowDate');
          if (lastBannerShowDate !== dayjs().format('YYYY-MM-DD')) {
            this.bannerShow = true;
          }

          if (this.bannerShow) {
            this.timeout1 = setTimeout(() => {
              localStorage.setItem('lastBannerShowDate', dayjs().format('YYYY-MM-DD'));
              this.bannerShow = false;
            }, bannerDuration * 1000);
          }
        });
    },
    useInstructions() {
      this.changeImgType('instructions', () => {
        this.$track('bangzhu_click');
        this.$router.push('/my/instructions');
      });
    },
    handleIncense() {
      this.$utils.useIncense(
        {
          serial_id: this.serialId,
          from: 'home',
          incense_capacity: this.incenseCapacity,
          empty_toast: '香薰已用尽，请及时添加香薰；或者插上电源后再尝试。',
        },
        () => {
          this.clickImgType = 'incense';
          this.handleYan();
        }
      );
    },
    handleYan() {
      this.playSvga(
        '#yan-svga-device-index',
        'https://mmc-forecast.oss-cn-shanghai.aliyuncs.com/file/1ef5301c-fa83-4e5a-97da-47b9d8c149b3.svga',
        1
      );
    },
    async playSvga(dom, svga, loops) {
      const player = new svgaplayerweb.Player(dom);
      const parser = new svgaplayerweb.Parser(dom);
      parser.load(svga, videoItem => {
        player.clearsAfterStop = true; // 动画结束时，是否清空画布
        loops && (player.loops = loops);
        player.setVideoItem(videoItem);
        player.startAnimation();
        player.onFinished(() => {});
      });
    },
    goSetting() {
      this.changeImgType('setting', () => {
        this.$track('setting_click');
        this.$router.push(`/settings/index?serial_id=${this.serialId}`);
      });
    },
    handleTask() {
      this.$router.push(`/fude/dailyTaskList?serial_id=${this.serialId}`);
    },
    handleMotto() {
      this.changeImgType('family_motto', () => {
        this.$router.push(`/edu/list?serial_id=${this.serialId}`);
      });
    },
    handleLesson() {
      this.$router.push(`/music/lessonList?serial_id=${this.serialId}`);
    },
    handleMusic() {
      this.$router.push(`/music/musicIndex?serial_id=${this.serialId}`);
    },
    handleClick(from) {
      if (from === 'solo') {
        this.$track('enter_dangui_gongfeng_pages');
      }
      if (['god', 'western'].includes(this.indexMode)) {
        this.handleGod(from);
      } else if (this.indexMode === 'earth_god') {
        this.handleEarthGod(from);
      } else {
        this.handleDeceased(from);
      }
    },
    handleDeceased(from) {
      if (from === 'multi') {
        this.$track('enter_sangui_tow_gongfeng_pages');
      }
      this.$router.push(`/deceased/enshrineList?serial_id=${this.serialId}&god_type=3`);
    },
    handleEarthGod(from) {
      if (from === 'multi') {
        this.$track('enter_sangui_three_gongfeng_pages');
      }
      this.$router.push(`/god/index?serial_id=${this.serialId}&god_type=2`);
    },
    handleGod(from) {
      if (from === 'multi') {
        this.$track('enter_sangui_one_gongfeng_pages');
      }
      this.$router.push(`/god/index?serial_id=${this.serialId}&god_type=1`);
    },
    woodfishFn() {
      this.$track('H5_shouye_muyu_click');
      this.$router.push({
        name: 'woodfish',
        query: {
          serial_id: this.$route.query.serial_id,
        },
      });
    },
  },
};
</script>

<style lang="less">
@media (min-height: 667px) {
  .device-index-container {
    height: calc(var(--vh) * 100) !important;
    overflow: hidden;
  }
}
.device-index-container {
  width: 100%;
  min-height: calc(var(--vh) * 100);
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  .yan-svga {
    width: 70px;
    height: 120px;
    position: absolute;
    right: 4px;
    bottom: 70px;
    z-index: 3;
  }
  .playing-box {
    width: 170px;
    height: 32px;
    line-height: 32px;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    padding: 0 6px;

    .name {
      font-size: 14px;
    }
    .icon1 {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
    .icon2 {
      width: 14px;
      height: 14px;
    }
    .exit-btn {
      width: 39px;
      height: 22px;
      line-height: 24px;
      background: #f87a1b;
      border-radius: 12px;
      font-size: 12px;
      color: #ffffff;
      margin-left: 4px;
      text-align: center;
    }
    .musicPlay {
      width: 134px;
      height: 32px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .musicName {
        line-height: 12px;
        margin-bottom: 3px;
      }
      .musicProgress {
        width: 100%;
      }
    }
  }
  .download {
    height: 50px;
    padding: 6px 16px;
    box-sizing: border-box;
    background: #7b5439;
    .download-left {
      .download-icon {
        width: 38px;
        height: 38px;
        background: #fff;
        border-radius: 6px;
        margin-right: 12px;
        img {
          width: 26px;
          height: 19px;
        }
      }
      .download-title {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 20px;
      }
    }
    .downloac-btn {
      width: 80px;
      height: 28px;
      border-radius: 14px;
    }
  }
  .index-out-box {
    width: 100%;
    .banner {
      width: 100%;
      height: 50px;
      position: relative;
      .bg {
        width: 100%;
        height: 50px;
      }
      .btn {
        width: 95px;
        height: 43px;
        position: absolute;
        right: 30px;
        top: 5px;
      }
    }
    .header-icon {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      z-index: 3;
      padding: 10px 10px;
      .btn-img {
        width: 40px;
        height: 40px;
      }
    }
    .fude-box {
      font-size: 12px;
      position: absolute;
      right: -6px;
      top: 315px;
      z-index: 3;
      .title {
        color: #7b5439;
        position: absolute;
        text-align: center;
        top: 16px;
        transform: scale(0.8);
      }
      .num {
        color: #7b5439;
        position: absolute;
        text-align: center;
        top: 32px;
        width: 48px;
        transform: scale(0.9);
      }
      .bg {
        display: block;
        width: 63px;
        height: 63px;
      }
    }

    .background-img {
      position: absolute;

      width: 100%;
    }

    .index-box {
      position: relative;
      z-index: 2;
      width: 375px;
      height: calc(var(--vh) * 100);
      .incense-box {
        width: 65px;
        position: absolute;
        right: 0;
        top: 539px;
        z-index: 3;
        .incense-img {
          width: 65px;
          display: block;
        }
        .yan {
          position: absolute;
          bottom: 60px;
        }
      }

      .music-img {
        width: 65px;
        position: absolute;
        left: 4px;
        top: 539px;
        z-index: 3;
      }

      .lesson-img {
        width: 46px;
        position: absolute;
        left: 18px;
        top: 315px;
        z-index: 3;
      }
      .task-img {
        position: absolute;
        width: 47px;
        height: 55px;
        right: 10px;
        top: 180px;
        z-index: 3;
      }

      .family-motto-img {
        width: 44px;
        position: absolute;
        left: 20px;
        top: 450px;
        z-index: 3;
      }
      .woodfish-img {
        position: absolute;
        width: 60px;
        height: 50px;
        display: flex;
        cursor: pointer;
        right: 0;
        top: 450px;
        z-index: 3;
      }
      .multi-guizi-box {
        width: 260px;
        height: 520px;
        position: absolute;
        top: 123px;
        left: 50%;
        margin-left: -126px;
        .guizi-img {
          width: 260px;
          height: 520px;
          z-index: -2;
        }
        .god-box {
          position: absolute;
          width: 235px;
          height: 165px;
          top: 22px;
          left: 50%;
          margin-left: -118px;
          .god-img {
            width: 106px;
            height: 127px;
            top: 32px;
            position: absolute;
            left: 50%;
            margin-left: -53px;
            z-index: 2;
          }
          .left-flag-image,
          .right-flag-image {
            width: 18px;
            height: 129px;
            position: absolute;
          }
          .left-flag-image {
            top: 0;
            left: 30px;
          }
          .right-flag-image {
            top: 0;
            right: 30px;
          }
          .left-lamp-image,
          .right-lamp-image {
            width: 51px;
            height: 51px;
            position: absolute;
          }
          .left-lamp-image {
            top: 112px;
            left: 24px;
          }
          .right-lamp-image {
            top: 112px;
            right: 24px;
          }
        }

        .earth-god-box {
          position: absolute;
          width: 235px;
          height: 165px;
          top: 352px;
          left: 50%;
          margin-left: -118px;
          .earth-god-img {
            width: 104px;
            position: absolute;
            left: 50%;
            margin-left: -52px;
            z-index: 2;
            top: 14px;
          }
          .left-flag-image,
          .right-flag-image {
            width: 18px;
            height: 129px;
            position: absolute;
          }
          .left-flag-image {
            top: 0;
            left: 30px;
          }
          .right-flag-image {
            top: 0;
            right: 30px;
          }
          .left-lamp-image,
          .right-lamp-image {
            width: 51px;
            height: 51px;
            position: absolute;
          }
          .left-lamp-image {
            top: 108px;
            left: 29px;
          }
          .right-lamp-image {
            top: 108px;
            right: 24px;
          }
        }
        .deceased-box {
          position: absolute;
          width: 235px;
          height: 160px;
          top: 192px;
          left: 50%;
          margin-left: -118px;
          .left-lamp-image,
          .right-lamp-image {
            width: 51px;
            height: 51px;
            position: absolute;
          }
          .left-lamp-image {
            top: 104px;
            left: 26px;
          }
          .right-lamp-image {
            top: 104px;
            right: 26px;
          }

          .ancestral {
            width: 170px;
            position: absolute;
            left: 50%;
            top: 3px;
            margin-left: -85px;
            z-index: 2;
            overflow: hidden;
          }

          .deceased-avatar {
            width: 80px;
            position: absolute;
            left: 50%;
            top: 34px;
            margin-left: -43px;
            height: 130px;
            z-index: 2;

            .bg {
              position: absolute;
              width: 80px;
              margin-left: -40px;
              left: 50%;
            }
            .avatar {
              position: absolute;
              width: 58px;
              height: 86px;
              margin-left: -29px;
              left: 50%;
              top: 11px;
              z-index: 1;
            }
            .paiwei {
              position: absolute;
              width: 55px;
              height: 125px;
              top: -15px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 1;
              img {
                width: 100%;
                height: 100%;
              }
              .deceased-avatar-text {
                position: absolute;
                top: 22px;
                left: 50%;
                transform: translateX(-4px);
                height: 70px;
                width: 6px;
                .paiwei-title {
                  max-height: 100%;
                  text-align: center;
                  color: #faf6a8;
                  font-size: 0.15rem;
                  word-break: break-all;
                  letter-spacing: 0;
                  // clip-path: circle(100%);
                }
              }
            }
          }
        }
      }

      .solo-guizi-box {
        width: 260px;
        height: 520px;
        position: absolute;
        top: 123px;
        left: 50%;
        margin-left: -126px;
        .guizi-img {
          width: 260px;
          height: 520px;
          z-index: -2;
        }
        .deceased-mode {
          .enshrine-center-0 {
            position: absolute;
            width: 68px;
            height: 160px;
            top: 55px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            img {
              width: 100%;
              height: 100%;
            }
            .deceased-text {
              position: absolute;
              top: 30px;
              left: 50%;
              transform: translateX(-5px);
              height: 95px;
              width: 6px;
              .enshrine-name {
                max-height: 100%;
                text-align: center;
                font-size: 0.2rem;
                color: #faf6a8;
                word-break: break-all;
                letter-spacing: 0;
                // clip-path: circle(100%);
                // writing-mode: vertical-lr;
                // text-orientation: upright;
              }
            }
          }
          .deceased-box {
            width: 200px;
            position: absolute;
            left: 50%;
            height: 162px;
            margin-left: -100px;
            top: 50px;
            z-index: 2;
            overflow: hidden;
            .ancestral {
              width: 180px;
              top: 16px;
              position: absolute;
              left: 50%;
              margin-left: -90px;
              z-index: 2;
              overflow: hidden;
            }
            .bg {
              position: absolute;
              width: 104px;
              left: 50%;
              margin-left: -52px;
              z-index: 1;
              top: 20px;
            }
            .avatar {
              position: absolute;
              width: 70px;
              height: 100px;
              margin-left: -35px;
              left: 50%;
              top: 40px;
              z-index: 1;
            }
          }
          .left-lamp-image {
            top: 178px;
            left: 36px;
          }
          .right-lamp-image {
            top: 178px;
            right: 36px;
          }
          .left-lamp-image,
          .right-lamp-image {
            width: 51px;
            height: 51px;
            position: absolute;
          }
        }

        .solo-god-mode {
          width: 200px;
          position: absolute;
          left: 50%;
          height: 162px;
          margin-left: -100px;
          top: 50px;
          z-index: 2;
          .god-img {
            width: 104px;
            position: absolute;
            left: 50%;
            margin-left: -52px;
            z-index: 2;
            top: 36px;
          }
          .left-flag-image,
          .right-flag-image {
            width: 18px;
            height: 129px;
            position: absolute;
          }
          .left-flag-image {
            top: -10px;
            left: 20px;
          }
          .right-flag-image {
            top: -10px;
            right: 20px;
          }
          .left-lamp-image {
            top: 128px;
            left: 6px;
          }
          .right-lamp-image {
            top: 128px;
            right: 6px;
          }
          .left-lamp-image,
          .right-lamp-image {
            width: 51px;
            height: 51px;
            position: absolute;
            z-index: 3;
          }
        }
      }
      .fire-ani {
        z-index: 8;
        width: 60px;
        height: 60px;
        position: absolute;
        z-index: 1;
      }
      #fire-0 {
        top: 106px;
        left: 30px;
      }
      #fire-1 {
        top: 106px;
        right: 30px;
      }
      #fire-2 {
        top: 266px;
        left: 30px;
      }
      #fire-3 {
        top: 266px;
        right: 34px;
      }
      #fire-4 {
        top: 428px;
        left: 34px;
      }
      #fire-5 {
        top: 428px;
        right: 30px;
      }
      #fire-6 {
        top: 149px;
        left: 32px;
      }
      #fire-7 {
        top: 149px;
        right: 32px;
      }
    }
    .festivalCalendar {
      position: absolute;
      z-index: 1000;
      top: 71px;
      left: 64px;
    }
  }
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    .wrapper-icon {
      position: absolute;
    }
    .wrapper-icon1 {
      width: 264px;
      height: 99px;
      top: 308px;
      left: 50%;
      transform: translateX(-50%);
    }
    .wrapper-icon2 {
      width: 160px;
      height: 160px;
      left: 50%;
      top: 203px;
    }
    .wrapper-icon3 {
      width: 78px;
      height: 81px;
      top: 20px;
      right: 37px;
    }
  }
}
</style>
