export default {
  ossConfig: `/upload/oss_config`,
  //用户
  getWXSign: `/wechat/signature`,
  getOpenId: `/wechat/openid`,
  login: `/user/login`,
  logout: `/user/logout`,
  getCode: `/user/code`,
  userInfo: `/user/info`,
  verifyCode: `/user/code_verify`,
  register: `/user/register`,
  checkPhone: `/user/check_phone`,
  userPhone: `/user/phone`,
  autoBind: '/user/new/bind',
  unBind: `/user/unbind`,
  getUserDevices: `/user/machines`,
  delUser: `/user/delete`,
  getUserSig: `/im/user/getusersig`,

  // 神台机
  getDeviceIndex: `/machine/index`,
  getDeviceInfo: `/machine/info`,
  useIncense: `/user/use/incense`,
  systemConfig: '/sys_conf',

  // 神佛
  currentGodList: `/machine/user/gods`,
  currentGod: `/machine/god`,
  offeringsList: `/offering/list`,
  setOffering: `/offering/new/save`,
  earthGodList: `/god/earth/list`,
  westernGodList: `/god/western/list`,
  godDetail: `/god/detail`,
  setGodDefault: '/god/set/default',
  inviteGod: `/god/invite`,
  religionGodList: `/god/religion/list`,
  setDefaultGod: `/god/set_default`,
  sendGod: `/god/return`,
  cancelSendGod: `/god/cancel_return`,
  userGodFude: `/fude/user/god/log`,
  userFude: `/fude/user/log`,

  // 逝者

  deceasedList: `/deceased/list`,
  setDefault: `/deceased/default`,
  deceased: `/deceased`,
  addDeceased: `/deceased/add`,
  delDeceased: `/deceased/delete`,
  editDeceased: `/deceased/save`,
  priorityWorship: `/deceased/precedence`,

  // 家风家训
  mottoList: `/family_motto/user/list`,
  addMotto: `/family_motto/add`,
  mottoDetail: `/family_motto/user/detail`,
  delMotto: `/family_motto/user/delete`,
  delMottos: `/family_motto/machine/batch/delete`,
  eduList: `/article/list`,
  eduType: `/article/get_edu_cate`,

  // 设置
  getAccount: `/machine/account`,
  generalConfig: `/config/general`,
  addTiming: `/config/add_incense_timing`,
  timingInfo: `/config/incense_timing_info`,
  incenseTiming: `/config/incense_timing`,
  delTiming: `/config/del_incense_timing`,
  voiceWord: `/machine/voice_word`,
  mode: `/config/index/mode`,
  religionList: `/machine/religion`,
  religion: `/config/religion`,
  setModeAndReligion: `/machine/set/worship_mode`,

  // 消息
  msgList: `/message/list`,
  delMsg: `/message/batch/delete`,
  devicesMsgList: `/message/machine/list`,
  msgConfig: `/message/user/config`,
  updateMsgConfig: `/message/update/config`,
  // 文件
  uploadImg: `/upload/image`,
  uploadVideo: `/upload/video`,
  uploadAudio: `/upload/audio`,
  getUploadVideoAuth: `/upload/video/auth`,
  machineVolume: `/machine/volume`,

  // 佛音
  musicList: `/machine/music`,

  // 功课
  lessonList: `/curriculum/user`,
  lessonDetail: `/curriculum/detail/user`,
  batchDeleteLesson: `/curriculum/batch_delete`,
  saveLesson: `/curriculum/save`,
  addLesson: `/curriculum/add`,

  // 商城
  goods: `/shop/goods`,
  goodsDetail: `/shop/goods/detail`,
  userMachines: `/shop/user/machines`,
  setDefaultMachine: `/shop/set_default_machine`,
  exchangeGift: `/shop/gift_bag/exchange`,
  // vip
  welfare: `/machine/member/welfare`,

  createOrder: `/order/create`,
  createEntityOrder: `/order/create_entity_order`,
  orderList: `/order/list`,
  orderDetail: `/order/detail`,
  userCoin: `/user/coin`,
  checkCompatible: `/god/compatible/check`,
  setCompatible: `/god/compatible/worship`,

  getMonth: '/almanac/month',
  getDay: '/almanac/day',
  getLatelyFestival: '/festival/lately',
  getFestivalDetail: '/festival',

  getXCXLink: '/resources/config',
};
