<template>
  <div class="f fv fc page-box">
    <img src="https://img-fe.tengzhihh.com/image/c4477fbb7d2f6d-228x282.png" class="logo-img" />
    <img src="https://img-fe.tengzhihh.com/image/0563000ddcff6a-178x52.png" class="slogan-img" />
    <div class="f fv">
      <van-button class="login-btn" type="info" @click="phoneLogin"> 手机号登录 </van-button>
      <van-button class="login-btn wc" type="primary" @click="weChatLogin" v-if="$tools.isWeChat()">
        微信登录
      </van-button>
    </div>
    <div class="f fc">
      <van-checkbox v-model="checked" checked-color="#F87A1B" icon-size="14px">
        <span class="tip"
          >我已阅读并同意眷吉的
          <span @click.stop.prevent="$router.push('/my/userAgreement')" style="color: #f87a1b">用户协议</span> 和
          <span @click.stop.prevent="$router.push('/my/privacyPolicy')" style="color: #f87a1b">隐私政策</span></span
        >
      </van-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    weChatLogin() {
      if (!this.checked) {
        return this.$toast('请先阅读并同意用户协议和隐私策略');
      }
      this.$tools.weChatLogin();
    },
    phoneLogin() {
      if (!this.checked) {
        return this.$toast('请先阅读并同意用户协议和隐私策略');
      }

      this.$emit('setValue', 'pageStep', 3);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 100%;
  height: calc(var(--vh) * 100);
  .logo-img {
    width: 114px;
    height: 141px;
    margin: 130px 0 24px;
  }
  .slogan-img {
    width: 89px;
    height: 26px;
    margin-bottom: 104px;
  }

  .login-btn {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    line-height: 44px;
    border: none;
    margin-bottom: 14px;
    width: 299px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px;
    font-weight: 400 !important;
  }
  .wc {
    background-color: #fff;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .tip {
    font-size: 12px;
    color: #838383;
    line-height: 13px;
    margin-top: 18px;
  }
}
</style>
