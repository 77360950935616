<template>
  <div class="cover-mode f fv fc">
    <img src="https://img-fe.tengzhihh.com/image/c4477fbb7d2f6d-228x282.png" class="cover-img" />
    <img src="https://img-fe.tengzhihh.com/image/d87eef6dfc9d9b-38x232.png" class="cover-slogan" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.cover-mode {
  width: 100%;
  height: calc(var(--vh) * 100);
  background: #fff;
  .cover-img {
    width: 114px;
    height: 141px;
    margin: 130px 0 24px;
  }
  .cover-slogan {
    width: 19px;
    height: 116;
  }
}
</style>
