import request from '@/utils/request';
import apiPath from '@/utils/apiPath';
import store from '@/store';
import router from '@/router/index';
import { Toast } from 'vant';
import wToast from '@/utils/toast';
import { track } from '@/utils/tracking';

export default {
  checkToken(cb, from = '') {
    if (store.getters.userAccessToken) {
      this.getUserInfo(cb, from);
    } else {
      Toast('请先登录');
      router.replace(`/login/index?from=${encodeURIComponent(from)}`);
    }
  },
  getUserInfo(cb, from = '') {
    request.get(apiPath.userInfo).then(res => {
      if (res.data) {
        store.commit('setUserInfo', res.data);
        typeof cb === 'function' && cb(from);
      } else {
        Toast('请先注册登录');
        router.replace(`/login/index?from=${encodeURIComponent(from)}`);
      }
    });
  },
  logout(callback) {
    store.dispatch('clearUserData').then(() => {
      typeof callback === 'function' && callback();
      router.replace(`/login/index${location.search}`);
    });
  },

  useIncense({ serial_id, from = '', id = '', incense_capacity = 100, empty_toast = '香薰已用完，请及时补充' }, cb) {
    if ([0, -1].includes(incense_capacity)) {
      Toast(empty_toast);
      return;
    }
    const formData = new FormData();
    formData.append('serial_id', serial_id);
    from && formData.append('from', from);
    id && formData.append('id', id);
    request.formPost(apiPath.useIncense, formData).then(res => {
      typeof cb === 'function' && cb();
      track('shangxiang_total_click');
      from === 'wap_index' && track('shouye_xiangxun_click');
      ['god', 'earth_god'].includes(from) && track('gongfeng_shangxiang_click');
      wToast({
        icon: 'https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png',
        content: res.data.fude ? `敬香成功，功德值+${res.data.fude}` : `敬香成功`,
      });
    });
  },
  getGeneralConfig(serial_id) {
    return new Promise((resolve, reject) => {
      request
        .get(apiPath.generalConfig, {
          serial_id,
        })
        .then(res => {
          const tmp = Object.assign({}, res.data, {
            dnd_mode: res.data.dnd_mode === 'open',
            backlight: res.data.backlight === 'open',
            incense_sound: res.data.incense_sound === 'open',
            festival_tab: res.data.festival_tab === 1,
          });
          store.commit('setGeneralConfig', tmp);
          resolve(tmp);
        });
    });
  },
  // new_config = [{key:'',value:''}]
  updateGeneralConfig(serial_id, new_config = [], cb) {
    const formData = new FormData();
    formData.append('serial_id', serial_id);
    new_config.forEach(i => {
      formData.append(i.key, i.value);
    });
    request.formPost(apiPath.generalConfig, formData).then(() => {
      Toast('修改成功');
      this.getGeneralConfig(serial_id);
      typeof cb === 'function' && cb();
    });
  },
  async kefuDialog(dialog) {
    const res = await request.get(apiPath.systemConfig, {
      identifier: 'customer_service',
    });
    const img = res.data.wap_image;
    typeof dialog.initDialog === 'function' &&
      dialog.initDialog({
        title: '联系客服',
        content: `<div class="f fc fv"><p style="textAlign:center;color: rgba(0,0,0,0.65);font-size: 16px;">添加客服微信一对一解答问题</p>
      <img src="${img}" style="width:128px;height:128px;margin-top:30px;" /></div>
      `,
        showCancelBtn: false,
        confirmText: '长按二维码添加客服',
        confirmCallback: () => {
          dialog.closeDialog();
        },
      });
  },

  calcUserIsMasterWithSerialId(serialId) {
    if (store.state.env === 'isLocal') {
      return true;
    }
    const list = store.state.userMachinesList;
    return !!list.find(i => i.serial_id === serialId)?.is_master;
  },
  checkAuth(serialId, cb) {
    const isMaster = this.calcUserIsMasterWithSerialId(serialId);
    if (isMaster) {
      typeof cb === 'function' && cb();
    } else {
      Toast('抱歉，您不是当前神台机主账号，无权进行相关操作');
      if (store.state.env === 'isLocal') {
        typeof cb === 'function' && cb();
      }
    }
  },
};
