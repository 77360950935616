var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bottom-box f fc frd" },
    _vm._l(_vm.calcList, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "f fv fvc",
          on: {
            click: function ($event) {
              return _vm.jump(item)
            },
          },
        },
        [
          _vm.currentRouteInfo.title === item.name
            ? _c("img", {
                staticClass: "icon",
                attrs: { src: item.activeIcon },
              })
            : _c("img", {
                staticClass: "icon",
                attrs: { src: item.defaultIcon },
              }),
          _vm._v(" " + _vm._s(item.name) + " "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }