<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getStore } from '@/utils/localStore';
import apiPath from '@/utils/apiPath';
export default {
  created() {
    const token = getStore({ name: 'user_access_token' });
    const openId = getStore({ name: 'user_open_id' });
    token && this.$store.commit('setUserAccessToken', token);
    openId && this.$store.commit('setUserOpenId', openId);
    token && this.getUserDevices();
  },
  methods: {
    getUserDevices() {
      this.$http.get(apiPath.getUserDevices).then(res => {
        this.$store.commit('setUserMachinesList', res.data.list);
        this.$store.commit('setSiMiaoLianMingGodGoodsId', res.data.banner[0]?.id);
      });
    },
  },
};
</script>
<style lang="less">
@import url('./public/public.css');
* {
  margin: 0;
  padding: 0;
}
#app {
  position: relative;
  width: 375px;
  min-height: calc(var(--vh) * 100);
  margin: 0 auto;
  overflow-x: hidden;
}
// audio {
//   display: none !important;
// }
.van-popup {
  border-radius: 15px;
}
.van-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.van-picker__title {
  font-weight: normal;
}
.van-button {
  &:active::before {
    opacity: 0.1;
  }
}
.click-hover:active {
  opacity: 0.7;
}

.popup-line {
  width: 48px;
  height: 6px;
  background: #dddddd;
  border-radius: 4px 4px 4px 4px;
  margin: 20px auto;
}
#cus-toast {
  flex-direction: row;
  width: fit-content;
  padding: 2px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(0, 0, 0, 0.7);
  max-width: 70%;
  word-break: break-all;
  z-index: 2023;
  border-radius: 30px;
  color: #fff;
  #cus-toast-icon {
    width: 45px;
    height: 45px;
    margin-right: 5px;
  }
  span {
    width: max-content;
    display: inline-block;
    font-size: 16px;
  }
}
</style>
