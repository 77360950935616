<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
    <selector
      :title="selectorTitle"
      :show="selectorShow"
      :columns="selectorColumns"
      :defaultIndex="findIndex(generalConfig.spray_duration)"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    >
    </selector>
  </div>
</template>

<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import Selector from '@/components/Selector/Selector.vue';
import bus from '@/utils/bus';
import sendMsg from '@/webSocket/sendMsg';
import { mapState } from 'vuex';
const sprayTimeArr = ['5秒', '10秒', '20秒', '30秒'];
const sprayTimeReportArr = [
  'setting_xiangxun_time_length_five',
  'setting_xiangxun_time_length_ten',
  'setting_xiangxun_time_length_twenty',
  'setting_xiangxun_time_length_thirty',
];

export default {
  components: { CellGroup, Selector },
  data() {
    return {
      selectorTitle: '',
      selectorShow: false,
      selectorColumns: [],
      incense_capacity: 0,
    };
  },
  computed: {
    ...mapState(['generalConfig']),
    settingList: function () {
      return [
        {
          title: '香薰容量',
          isLink: true,
          cb: () => {
            this.$router.push(`/settings/capacity?serial_id=${this.serialId}&capacity=${this.incense_capacity}`);
          },
          value: this.incense_capacity + '%',
        },
        {
          title: '定时敬香',
          isLink: true,
          cb: () => {
            this.$router.push(`/settings/timingList?serial_id=${this.serialId}`);
          },
        },
        {
          title: '敬香时长',
          isLink: true,
          cb: () => {
            this.openSelector(0);
          },
          value: this.generalConfig.spray_duration + '秒',
        },
      ];
    },
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    bus.$on('onMsg', (serialId, content) => {
      if (serialId !== this.serialId) return false;
      this.updateBatteryStatus(content);
    });
    this.$webSocket.initIM(this.serialId).then(() => {
      sendMsg('systemInfo', {
        operation: 'getBatteryStatus',
      });
    });
    this.$utils.getGeneralConfig(this.serialId);
  },
  beforeDestroy() {
    bus.$off('onMsg');
  },
  methods: {
    updateBatteryStatus(msg) {
      if (msg.action !== 'systemInfo') return;
      if (msg.data.incenseCapacity <= 0) return;
      //香薰容量
      this.incense_capacity = msg.data.incenseCapacity;
    },
    findIndex(second) {
      return sprayTimeArr.findIndex(i => i === second + '秒');
    },
    openSelector(type) {
      const arr = ['喷洒时长'];
      const columns = [sprayTimeArr];
      this.selectorTitle = arr[type];
      this.selectorColumns = columns[type];
      this.selectorShow = true;
    },
    onConfirm(e, idx) {
      this.$utils.updateGeneralConfig(
        this.serialId,
        [
          {
            key: 'spray_duration',
            value: sprayTimeArr[idx].match(/\d+/g),
          },
        ],
        () => {
          this.$track(sprayTimeReportArr[idx]);
          this.selectorShow = false;
        }
      );
    },
    onCancel() {
      this.selectorShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
