<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
  </div>
</template>

<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import { mapState } from 'vuex';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  components: { CellGroup },
  mixins: [commonOperate],
  data() {
    return {
      settingList: [],
    };
  },
  computed: {
    ...mapState({
      generalConfig: state => state.generalConfig,
    }),
  },
  mounted() {
    this.initList();
  },
  methods: {
    async initList() {
      await this.$utils.getGeneralConfig(this.routeQuery.serial_id);
      this.settingList = [
        {
          title: '神台机设置',
          isLink: true,
          path: `/settings/client?serial_id=${this.routeQuery.serial_id}`,
          cb: () => {
            this.$track('setting_shentaiji_click');
          },
        },
        {
          title: '模式选择',
          isLink: true,
          path: `/settings/modeSelect?serial_id=${this.routeQuery.serial_id}&from=mode_settings`,
          cb: () => {
            this.$track('setting_moshi_click');
          },
        },
        {
          title: '外观设置',
          isLink: true,
          path: `/settings/appearanceSetting?serial_id=${this.routeQuery.serial_id}`,
          filter: this.generalConfig.index_mode === 'western',
        },
        {
          title: '显示设置',
          isLink: true,
          path: `/settings/show?serial_id=${this.routeQuery.serial_id}`,
          cb: () => {
            this.$track('setting_xianshi_click');
          },
        },
        {
          title: '音效设置',
          isLink: true,
          path: `/settings/sound?serial_id=${this.routeQuery.serial_id}`,
          cb: () => {
            this.$track('setting_yinxiao_click');
          },
        },
        {
          title: '香薰设置',
          isLink: true,
          path: `/settings/aroma?serial_id=${this.routeQuery.serial_id}`,
          cb: () => {
            this.$track('setting_xiangxun_click');
          },
        },
      ].filter(x => x.filter !== true);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
